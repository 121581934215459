body {
    font-family: 'Arial', sans-serif;
    background-color: #f4f4f4;
}

.form-group {
    margin-bottom: 20px;
}

.form-control {
    width: 100%;
    /* padding: 10px; */
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.form-control:focus {
    outline: none;
    border-color: #66afe9;
    box-shadow: 0 0 5px rgba(102, 175, 233, 0.5);
}

.form-control-file {
    display: block;
}

textarea.form-control {
    resize: vertical;
}

.btn {
    display: inline-block;
    padding: 8px 16px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
}

.btn-primary {
    background-color: #007bff;
    color: #fff;
}

.btn-primary:hover {
    background-color: #0056b3;
}

.btn-secondary {
    background-color: #6c757d;
    color: #fff;
}

.btn-secondary:hover {
    background-color: #5a6268;
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.table th,
.table td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #dee2e6;
}

.table th {
    background-color: #f8f9fa;
    color: #333;
}

.table tbody tr:hover {
    background-color: #f2f2f2;
}

.alert {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
}

.alert-danger {
    background-color: #f8d7da;
    border-color: #f5c6cb;
    color: #721c24;
}

.download-link {
    background-color: transparent; /* transparent background */
    border: none;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
  }
  
  .download-link svg {
    font-size: 16px;
    color: #337ab7; /* blue icon */
  }
  
  .download-link:hover svg {
    color: #23527c; /* darker blue icon on hover */
  }

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fadeIn {
    animation: fadeIn 0.5s ease-in-out;
}
