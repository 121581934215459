body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

/* Login */

.login-bg {
  background-image: url('https://clipground.com/images/flowing-ribbon-clipart-5.png');
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 100%;
}

.login-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.forms-group {
  margin-bottom: 15px;
}

.forms-group label {
  font-weight: bold;
}

.forms-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

button[type="submit"] {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

/* Home */

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.introduction {
  margin-top: 1%;
  margin-bottom: 20px;
}

.buttons-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.filter {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
}

.homeDesign {
  box-shadow: 0px 4px 20px 0px #5B47BC4D;
  background-color: #FFF;
  border-radius: 7px;
}

.textFont {
  font-size: 25px;
}

.featured-image {
  width: 100%;
  border-radius: 8px;
}

.chart-container {
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 7px;
  box-shadow: 0px 4px 20px 0px #5B47BC4D;
}

.custom-month-select {
  max-height: 100px; /* Limit the height for scrolling */
  overflow-y: auto;  /* Enable scrolling */
}

.custom-month-select option:hover {
  background-color: #AE2320;
  color: #fff;  /* White text for contrast */
}

/* .buttons-container button {
  margin: 0 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px; */
/* transition: background-color 0.3s; */
/* } */

/* .buttons-container button:hover {
  background-color: #0056b3;
} */

.data-container {
  display: flex;
  justify-content: space-around;
  width: 80%;
}

.data-container>div {
  flex: 1;
  margin: 0 10px;
}

.data-container h3 {
  margin-bottom: 10px;
}

.data-container ul {
  list-style: none;
  padding: 0;
}

.data-container ul li {
  margin-bottom: 5px;
}

/* Transaction */
.w100 {
  width: 100% !important;
}

.item-center {
  display: flex;
  justify-content: center;
  align-items: end;
}

.headerContainer {
  margin: 0 !important;
}

.mainContainer {
  margin: 30px 0 !important;
  padding-right: 30px;
}

h1 {
  font-weight: 600 !important;
  font-size: 32px !important;
}

.header {
  height: 100px;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
}

.headers {
  height: 100px;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
}

.logoSec {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 100%;
}

.munuContainer {
  display: flex;
  align-items: center;
  justify-content: end;
}

.menuLists {
  display: flex;
  align-items: center;
  justify-content: end;
}

.navigation-buttons {
  padding: 17px 25px;
  margin: 0 25px;
  border-radius: 50px;
  box-shadow: 0 5px 2px rgb(0 0 0 / 0.2);
}

.menuBtn.active,
.menuBtn:hover {
  font-weight: 600;
  color: #FFF;
  border-radius: 7px;
  text-decoration: none;
  background-color: #916430;
  transition: background-color 0.3s ease-in-out;
}

.menuBtn {
  padding: 10px 16px;
  text-decoration: none;
  font-size: 16px;
  color: #4d4d4d;
  font-weight: 600;
  text-align: center;
}

.logOut {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.userName {
  margin: 0 10px !important;
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.418);
  --bs-dropdown-link-active-bg: #AE2320 !important;
  --bs-dropdown-link-active-color: #ffffff !important;
  z-index: 1;
  right: 0;
}

.dropdown-menu.show {
  display: block;
  margin-top: 2px;
  border: 2px solid #AE2320;
  background-color: #f4f4f4;
  margin-top: 2px;
  border: none;
  position: absolute;
  width: 100%;
  left: -79%;
  top: 31px;
  text-align: center;
}

.dropdown-item {
  display: flex;
  align-items: center;
  min-height: 30px;
  background-color: #f4f4f4;
}

.leftSideContainer {
  padding: 25px 30px !important;
  border-top-right-radius: 17px;
  border-bottom-right-radius: 17px;
  background-color: #916430 !important;
}

.rightSideContainer {
  padding: 0 20px !important;
}

.contanerShad {
  border-radius: 7px;
  padding: 25px 20px !important;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.transaction-form,
.update-transaction {
  margin-top: 30px;
  border-radius: 8px;
  animation: fadeInUp 3s;
}

.locationContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 10px 0;
}

.addLocation {
  margin-top: 20px;
  color: #000 !important;
  background-color: #FFF !important;
  display: flex !important;
  align-items: center;
}
.icon-container .uploaded{
  margin: 0!important;
}
.addLocation .addIcon {
  margin-right: 5px;
}

.btn:focus-visible {
  box-shadow: none !important;
}

.locBtn {
  box-shadow: none !important;
  color: #000 !important;
  background-color: transparent !important;
  border: 2px solid #AE2320 !important;
  padding: 5px 30px !important;
  margin: 0 20px 0 0;
}

.locBtns {
  box-shadow: none !important;
  color: #000 !important;
  background-color: transparent !important;
  border: 2px solid #916430 !important;
  padding: 5px 30px !important;
  margin: 0 20px 0 0;
}

.btn-active {
  color: #FFF !important;
  font-weight: 600 !important;
  border: 1px solid #ffffff !important;
  background-color: #AE2320 !important;
}

.transaction-form h2,
.update-transaction h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #FFF;
}

.form-label {
  color: #FFF;
}

.filters .form-label {
  color: #000;
}

.custom-upload-wrapper {
  padding-top: 20px;
}

.upload-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.custom-upload {
  display: flex;
  align-items: center;
  background-color: #AE2320;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  position: relative;
  margin-right: 10px;
  border: 1px solid #fff !important;
  min-width: 50%;
  font-family: Poppins !important;
  width: 50%;
}

.upload-text {
  cursor: pointer;
}

.upload-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.hidden-file-input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
}

.file-names {
  color: #ffffff;
  font-size: medium;
  overflow: auto;
}

.file-name {
  color: #AE2320;
  font-size: large;
  overflow: auto;
}

.submitBtn {
  color: #000 !important;
  font-weight: 600 !important;
  background-color: #FFF !important;
  border: 2px solid #AE2320 !important;
}

.submitBtn:hover {
  color: #FFF !important;
  background-color:#AE2320  !important;
  border: 2px solid #FFF !important;
  
  font-weight: 600 !important;
}
.submitBtns {
  color: #ffffff !important;
  font-weight: 600 !important;
  background-color: #AE2320 !important;
  border: 2px solid #fff !important;
}

.submitBtns:hover {
  color: #000000 !important;
  background-color:  #fff!important;
  border: 2px solid  #AE2320!important;
  font-weight: 600 !important;
}

.cancelBtn {
  color: #FFF !important;
  background-color: #AE2320 !important;
  font-weight: 600 !important;
  border: 1px solid #AE2320 !important;
  width: 100%;
}

/* .cancelBtn:hover{
  color: #AE2320!important;
  background-color: #fff!important;
} */
.filters {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  margin-bottom: 20px;
}

.filterGrp {
  display: flex;
  flex-wrap: wrap;
  align-items: end;
  gap: 14px;
}

.filterRow {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 10px;
}

.filterRow .form-control {
  margin: 0;
}

.exCSV {
  height: fit-content;
  background-color: #AE2320 !important;
}

.search-input {
  width: 150px !important;
  margin-left: 10px;
}


.totals {
  display: flex;
  justify-content: space-evenly;
  padding: 10px;
  border-radius: 5px;
  flex-wrap: wrap;
}

.amountCard {
  width: 25%;
  padding: 10px;
  border-radius: 7px;
  text-align: center;
  position: relative;
  font-weight: 600;
  padding-bottom: 0px;
}

.amountCard img {
  position: absolute;
  position: absolute;
  top: -18px;
  left: -12px;
  width: 22%;
}

.incomeCard {
  background: radial-gradient(97.6% 204.44% at 2.4% 7.14%, #DEFD88 0%, #08774C 100%)
}

.expenseCard {
  background: radial-gradient(97.6% 204.44% at 2.4% 7.14%, #FFC6C5 0%, #E33733 100%)
}

.balanceCard {
  color: #FFF !important;
  background: radial-gradient(97.6% 204.44% at 2.4% 7.14%, rgba(0, 0, 0, 0.03) 0%, #000000 100%)
}

.amountCard b {
  font-size: 29px;
  line-height: 63px;
}

table th {
  color: #FFF !important;
  background-color: #AE2320 !important;
}

.actionCell {
  padding: 0 !important;
}

.actionContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.actionBtn {
  color: #FFF !important;
  display: flex !important;
  align-items: center !important;
  background-color: #AB7F4F !important;
  margin: 5px;
}

.actionBtn:focus,
.btn:focus {
  box-shadow: none !important;
}

.actionBtnIcon {
  margin-right: 5px;
}

.action-checkbox {
  margin-left: 10px;
}

.form-control {
  border: 2px solid #AE2320 !important;
}

.form-controls {
  border: 2px solid #AE2320 !important;
  border-radius: 7px;
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  /* border-radius: var(--bs-border-radius); */
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

}

.transaction-page {
  margin: 0%;
  padding: 0%;
  width: 100%;
}

.navigation-buttons .navigation-button {
  margin: 0 10px;
}

.error-message {
  color: red;
  margin-bottom: 10px;
}

.recent-transactions {
  margin-top: 20px;
}

.weaverForm {
  background: #916430;
  padding: 20px;
  border-top-right-radius: 17px;
  border-bottom-right-radius: 17px;
}

.forms-groups {

  padding-top: 2px !important;

}

.forms-groups label {
  margin-top: 10px;
  font-size: 16px !important;
  color: #777070 !important;
}

label {
  margin-top: 10px;
}

.formBtn {
  border: 1px solid #AE2320 !important;
  padding-top: 10px !important;
  text-align: center !important;
  background-color: #AE2320 !important;
  color: #FFFFFF !important;
  font-family: Poppins !important;
  font-size: 20px !important;
  /* font-weight: 600 !important; */
  /* line-height: 42px !important; */
}

.weaverBtn {
  width: 40%;
  display: flex;
  justify-content: space-between;
}

.weaverFilters {
  display: flex;
  justify-content: space-between;
}

.recentWeaver {
  box-shadow: 0px 2px 20px 0px #00000040;
  border-radius: 7px;
  padding: 20px;
  padding-left: 10px;
}

.locBtn.active {
  color: #FFF !important;
  font-weight: 600 !important;
  border: 2px solid #AE2320 !important;
  background-color: #AE2320 !important;
}

.locBtns.active {
  color: #FFF !important;
  font-weight: 600 !important;
  border: 2px solid #916430 !important;
  background-color: #916430 !important;
}

/* Section Toggle Buttons */
button.btn {
  padding: 10px 20px;
  font-size: 16px;
  margin-right: 10px;
}

button.btn.active {
  background-color: #007bff;
  color: white;
}

.settingsCard {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  margin-top: 10px;
  width: 32%;
  background-color: #916430;
  color: white;
}

.w50 {
  display: flex;
  justify-content: center;
}

.inputControl {
  padding: 10px;
  width: 100%;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}


/* .table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 8px;
  border-bottom: 1px solid #dee2e6;
  text-align: left;
} */

/* .btn {
  display: inline-block;
  font-weight: 400;
  /* color: #212529; */
/* text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem; */
/* transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; */
/* cursor: pointer; */
/* }  */

/* .btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-secondary {
  color: #6c757d;
  background-color: transparent;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
} */

/* .navigation-buttons-top-left {
  display: flex;
  justify-content: space-between;
  width: 30%;
}

.container {
  padding-top: 20px;
}

.navigation-buttons { */
/* position: fixed;
  top: 0;
  left: 0; */
/* width: 100%;
  background-color: #c7fffaf9; */
/* z-index: 999; */
/* padding: 1%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.companyHeading { */
/* left: 30;
  position: relative;
  align-items: center; */
/* margin-bottom: 10px;
}

.nav-links {
  display: flex;
  align-items: center;
}

.navigation-button {
  margin-right: 10px;
}

.add-transaction {
  background-color: #f9f9f9;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-top: 1%;
}

.update-transaction {
  margin-top: 1%;
  background-color: #f9f9f9;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.btn-size {
  width: 100%;
}

.transaction-form {
  margin-bottom: 20px;
}

.recent-transactions {
  background-color: #f9f9f9;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
}

.filters {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.transHeading {
  text-align: center;
} */

/* .heading {
  margin-top: 1%;
  text-align: center;
  margin-bottom: 20px;
}

.filters label {
  margin-right: 10px;
}

.filters input[type="date"],
.filters select {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-right: 10px;
}

.transaction-table {
  width: 100%;
  border-collapse: collapse;
}

.transaction-table th,
.transaction-table td {
  border: 1px solid #dddddd;
  padding: 8px;
  text-align: left;
  border-radius: 5px;
}

.transaction-table th {
  background-color: #f2f2f2;
}

.transaction-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.transaction-table tbody tr:hover {
  background-color: #f0f0f0;
}

.totals {
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
  padding: 10px;
  background-color: #f2f2f2;
  border-radius: 5px;
} */

/* .totals h4 {
  margin-bottom: 10px;
}

/* @media (max-width: 768px) {

  .col-md-3,
  .col-md-9 {
    width: 100%;
  }
} */

/* .form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
}

.form-control-file {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
} */

.weaver-page {
  width: 99%;
  /* margin-top: 20px; */
  /* overflow-x: auto; */
  margin: 0;
  padding: 0;


}

.weaver-form input[type="file"] {
  margin-top: 12px;
}

.form-container {
  width: 30%;
  /* margin-right: 20px; */
}

.weaver-form {
  padding: 20px;
  background-color: #f2f2f2;
  border-radius: 5px;
  margin-top: 5px;
  margin-left: 5%;
}

.weavers-list {
  margin-top: 1%;
}

.weaver-form label {
  font-weight: bold;
}

.weaver-form input[type="text"],
.weaver-form input[type="number"],
.weaver-form select,
.weaver-form textarea {
  width: 100%;
  padding: 8px;
  margin-top: 6px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.weaver-form button {
  /* background-color: #4CAF50; */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.weaver-form button:hover {
  background-color: #4fb954e7;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.weavers-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.weavers-table th,
.weavers-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.weavers-table th {
  background-color: #f2f2f2;
}

.weavers-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.weavers-table tbody tr:hover {
  background-color: #f0f0f0;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.saree-design-page {
  padding: 20px;
}

.saree-design-form {
  margin-top: 1%;
}

.image-upload-container {
  margin-top: 20px;
}

.upload-preview {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.upload-preview img {
  max-width: 100%;
  height: auto;
}

.upload-button {
  margin-top: 10px;
}

.saree-photos {
  margin-top: 20px;
}

.saree-photo {
  margin-bottom: 20px;
}

.saree-photo img {
  max-width: 100%;
  height: auto;
}

/* @media (max-width: 768px) {
  .navigation-buttons {
    flex-direction: column;
    align-items: flex-start;
  }
} */

button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

/* button:hover {
  background-color: #0056b3;
} */

/* .saree-design-preview {
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.saree-design-preview table {
  width: 100%;
}

.saree-design-preview th,
.saree-design-preview td {
  padding: 10px;
  text-align: center;
  border: 1px solid #ddd;
}

.saree-design-preview th {
  background-color: #f2f2f2;
}

.saree-design-preview img {
  max-width: 150px;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.saree-design-preview img:hover {
  transform: scale(1.05);
}

.saree-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.saree-table tbody tr:hover {
  background-color: #f0f0f0;
}

.saree-design-page {
  padding: 20px;
  background-color: #f2f2f2;
  border-radius: 5px;
  margin-top: 1%;
  margin-left: 5%;
}

.saree-design-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 20px;
  justify-items: center;
}

.saree-design-item img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.saree-design-item h4 {
  text-align: center;
}

.weaver-name {
  margin-top: 10px;
  color: #6c757d;
} */

.weavers-table th {
  background-color: #007bff;
  color: #fff;
}

.weavers-table tbody tr:hover {
  background-color: rgba(0, 123, 255, 0.1);
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (max-width: 767px) {

  .navigation-button {
    margin-bottom: 10px;
  }

  .weavers-table,
  .saree-design-preview {
    margin-top: 20px;
  }

  .saree-design-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

  .saree-design-item {
    margin-bottom: 20px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .navigation-links {
    justify-content: center;
  }

  .weavers-table,
  .saree-design-preview {
    margin-top: 20px;
  }

  .saree-design-grid {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }
}

@media (max-width: 991px) {
  .companyHeading {
    font-size: 24px;
  }

  .introduction h2 {
    font-size: 20px;
  }

  .weavers-table,
  .saree-design-preview {
    padding: 10px;
  }

  .saree-design-item img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 575px) {
  .companyHeading {
    font-size: 20px;
  }

  .introduction h2 {
    font-size: 18px;
  }

  .navigation-button {
    font-size: 14px;
  }

  .weavers-table th,
  .weavers-table td {
    font-size: 14px;
  }

  .weavers-table th {
    padding: 8px;
  }

  .weavers-table td {
    padding: 8px;
  }

  .saree-design-item h4 {
    font-size: 12px;
  }

  .saree-design-item img {
    width: 100%;
    height: auto;
  }
}

.addSaree {
  color: white;
}

.design-card {
  background: linear-gradient(180deg, #AE2320 0%, #1B1414 100%);
  border-radius: 17px;
  position: relative;
  width: 156px;
  height: 187px;
  margin: 20px;
  /* margin-top: 30px; */
  text-align: center;
  box-shadow: 0px 4px 12px 0px #00000040;
  cursor: pointer;
  margin-bottom: 2%;
  padding-left: 3px;
  padding-right: 3px;
}

.design-card img {
  border: 3px solid #AE2320;
  border-radius: 7px;
  width: 150px;
  height: 150px;
  object-fit: cover;
  position: absolute;
  top: px;
  left: 50%;
  transform: translateX(-50%);
}

.design-info {
  background: #F5F5F5;
  border-radius: 5px;
  margin-top: -1px;
  width: 230px;
  border-bottom-right-radius: 14px;
  border-bottom-left-radius: 14px;
  height: 35px;
  text-align: center !important;
  padding-top: 3px;
}

.design-info h5{
  margin-top: 2px;
}

.W-0 {
  width: 100% !important;
}

.btn-end {
  color: #FFF !important;
  background-color: #AE2320 !important;
  border-radius: 7px;
  display: flex;
  justify-content: end;
  text-align: right !important;
}

.button-container {
  display: flex;
  justify-content: flex-end;
}

.view-sarees {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.design-details-heading {
  font-weight: bold;
  margin-bottom: 20px;
}

.design-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.design-layout {
  padding-top: 150px;
  display: flex !important;
  justify-content: center !important;
}

.design-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.design-cards {
  width: 180px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  padding: 10px;
  text-align: center;
  background: #916430;
}

.image-container {
  width: 150px;
  height: 150px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #e9e9e9;
}
.image-container img{
  width: 100%;
  height: 100%;
  margin: 0;
}
.placeholder-image {
  color: #999;
  font-size: 14px;
}

.img-fluids {
  /* max-width: 100%; */
  max-height: 100%;
  object-fit: cover;
}

.icon-container {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.icon-wrappers1 {
  cursor: pointer;
  padding: 7px;
  border-radius: 7px;
  background: #88A9FD;
}

.icon-wrappers1.uploaded {
  background: #88A9FD;
  /* New background color after file upload */
}

.icon-wrappers2 {
  cursor: pointer;
  padding: 7px;
  border-radius: 7px;
  background: #E25454;
  height: fit-content;
}

.icon-wrappers3 {
  cursor: pointer;
  padding: 7px;
  border-radius: 7px;
  background: #90E866;
  height: fit-content;
}

.action-icon {
  width: 20px;
  height: 20px;
}

.file-input {
  display: none;
}

.modal-overlays {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Ensures the modal stays above other elements */
}

.modal-contents {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  width: 80%;
  max-width: 800px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Subtle shadow for depth */
}

.design-details-heading {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.modal-closes {
  position: absolute;
  top: 10px;
  right: 20px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.modal-closes {
  color: black;
}

.modal-closes:hover {
  color: red;
}